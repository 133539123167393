<template>
  <b-container class="container-content">
    <b-row class="justify-content-center">
      <b-col xl="5" class="px-4 card-container shadow-lg">
        <h5 class="page-header-text mb-4">
          {{ $t("pages.welcome.welcome_to_bluuu") }}
        </h5>
        <p class="text-center">
          {{ $t("pages.welcome.thank_you_for_registering") }}
        </p>
        <p>{{ $t("pages.welcome.by_default") }}</p>
        <p>
          {{ $t("pages.welcome.secondary_email_desc") }}
        </p>
        <p>
          {{ $t("pages.welcome.leave_secondary_email") }}
        </p>
        <b-form-group
          :label="$t('form_label.your_facebook_email')"
          class="mb-4"
        >
          <b-form-input :value="email" disabled></b-form-input>
        </b-form-group>
        <!-- <b-form-group :label="$t('form_label.your_secondary_email')">
          <b-form-input
            v-model.trim="secondaryEmail"
            placeholder="mail@example.com"
            type="email"
          ></b-form-input> 
        </b-form-group>-->
        <b-button
          variant="primary"
          block
          @click="doContinue"
          :disabled="!canContinue"
          >{{ $t(`button.${buttonText}`) }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { createNamespacedHelpers } from "vuex";

const UserStore = createNamespacedHelpers("user");
const AuthStore = createNamespacedHelpers("auth");

@Component({
  computed: {
    ...UserStore.mapState(["email"]),
    ...AuthStore.mapState(["callBackPathAfterWelcomePage"]),
  },
  methods: {
    ...UserStore.mapActions(["updateUserProfile"]),
  },
})
export default class Welcome extends Vue {
  secondaryEmail = "";
  buttonText = "continue";
  async doContinue() {
    if (this.isValidEmail) {
      this.buttonText = "updating";
      await this.updateUserProfile({ email: this.secondaryEmail });
    }
    this.buttonText = "continue";
    // if (this.callBackPathAfterWelcomePage) {
    //   const path = this.callBackPathAfterWelcomePage;
    //   this.$router.push({ path });
    // } else {
    //   // this.$router.push({ name: 'Home' });
    // }
    // const path = this.callBackPathAfterWelcomePage;
    // this.$router.push({ path });
    this.$router.go(-1);
  }

  // fix me -> move to util function
  get isValidEmail() {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(String(this.secondaryEmail).toLowerCase());
  }

  get canContinue() {
    return this.isValidEmail || !this.secondaryEmail;
  }
}
</script>
<style lang="css" scoped>
.card-container {
  min-height: auto;
}
</style>
